import {
  DataGridPro,
  GridColDef,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel
} from "@mui/x-data-grid-pro";
import { ClearingAllocation, DisciplineAuthorisationCommentValue } from "@/interfaces";
import { NVCPClearingAllocationTableToolbar } from "./NVCPClearingAllocationTableToolbar";
import { useEffect, useState } from "react";
import { useDisciplineTabContext } from "@/context";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

interface NVCPClearingAllocationTableProps {
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  childValueConfigurations: DisciplineAuthorisationCommentValue[] | undefined;
  disabled: boolean;
  onDelete: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  onUpdateChildAuthComment: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isEditable: boolean;
  onActionHandler: (isEdit: boolean) => void;
}

export function NVCPClearingAllocationTable({
  disabled,
  onActionHandler,
  disciplineAuthCommentValue
}: NVCPClearingAllocationTableProps) {
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const [rowIsSelected, setRowIsSelected] = useState<boolean>(true);
  const { clearingAllocations, updateDisciplineAuthorisationCommentId, updateClearingAllocations } =
    useDisciplineTabContext();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const dataColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.25,
      sortable: false
    },
    {
      field: "referenceNo",
      headerName: "Instrument",
      flex: 0.25,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, string>) =>
        params.row.id === "Total" ? <span style={{ fontWeight: "bold" }}>Total</span> : <span>{params.value}</span>
    },
    {
      field: "availableBalance",
      flex: 0.25,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, number>) =>
        params.row.id === "Total"
          ? null
          : renderDisplayValueWithUnit(
              params.row.remainingTotalApprovedClearing -
                clearingAllocations
                  .filter((row) => params.row.referenceNo === row.referenceNo)
                  .reduce((sum, row) => sum + row.clearingAllocation, 0)
            ),
      type: "number",
      headerName: "Available Balance",
      headerAlign: "right",
      align: "right",
      sortable: false
    },
    {
      field: "clearingAllocation",
      headerName: "Clearing Allocation",
      flex: 0.25,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, number>) =>
        renderDisplayValueWithUnit(params.value),
      type: "number",
      headerAlign: "right",
      align: "right",
      sortable: false
    },
    {
      field: "restrictedClearing",
      headerName: "Restricted Clearing",
      flex: 0.5,
      sortable: false
    },
    {
      field: "restrictedAvailableBalance",
      headerName: "Restricted Available Balance",
      flex: 0.25,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, number>) =>
        params.row.id === "Total"
          ? null
          : renderDisplayValueWithUnit(
              params.row.remainingTotalRestrictedClearing -
                clearingAllocations
                  .filter((row) => params.row.referenceNo === row.referenceNo)
                  .reduce((sum, row) => sum + row.restrictedClearingAllocation, 0)
            ),
      type: "number",
      headerAlign: "right",
      align: "right",
      sortable: false
    },
    {
      field: "restrictedClearingAllocation",
      headerName: "Restricted Clearing Balance",
      flex: 0.25,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, number>) =>
        renderDisplayValueWithUnit(params.value),
      type: "number",
      headerAlign: "right",
      align: "right",
      sortable: false
    }
  ];

  const renderDisplayValueWithUnit = (value: number | undefined) => (
    <span>
      <span>{value?.toFixed(2)}</span>
      <span style={{ color: "GrayText" }}> ha</span>
    </span>
  );

  useEffect(() => {
    updateDisciplineAuthorisationCommentId(disciplineAuthCommentValue.disciplineAuthorisationCommentId);
  }, [disciplineAuthCommentValue.disciplineAuthorisationCommentId, updateDisciplineAuthorisationCommentId]);

  const pinnedRows: GridPinnedRowsProp<ClearingAllocation> = {
    bottom: [
      {
        referenceNo: "Total",
        id: "Total",
        disciplineAuthorisationCommentId: "",
        instrumentId: "",
        clearingAllocation: clearingAllocations.reduce((sum, row) => sum + row.clearingAllocation, 0),
        noClearingAfterDate: null,
        expiryDate: null,
        instrumentRestrictionAreaId: null,
        restrictedClearing: null,
        restrictedClearingAllocation: clearingAllocations.reduce(
          (sum, row) => sum + row.restrictedClearingAllocation,
          0
        ),
        termsAndConditions: "",
        totalApprovedClearing: 0,
        assignedClearing: 0,
        totalRestrictedClearing: 0,
        assignedRestrictedClearing: 0,
        remainingTotalRestrictedClearing: 0,
        remainingTotalApprovedClearing: 0
      }
    ]
  };

  // Draft function to let tester to test the grid in empty state.
  // TODO: to replace with proper function later.
  const handleDelete = () => {
    updateClearingAllocations(clearingAllocations.filter((row) => row.id !== selectedRowId));
  };

  const handleClickAway = () => {
    if (!rowIsSelected) return;
    setRowSelectionModel([]);
    resetToRowUnselectedState();
  };

  const resetToRowUnselectedState = () => {
    setSelectedRowId("");
    setRowIsSelected(false);
    onActionHandler(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <DataGridPro
        autoHeight={true}
        columns={dataColumns}
        data-testid="nvcp-clearing-allocation-table"
        density="compact"
        disableColumnMenu={true}
        pagination={false}
        getRowId={(row: ClearingAllocation) => row.id}
        rowCount={clearingAllocations.length}
        rows={clearingAllocations}
        pinnedRows={clearingAllocations.length !== 0 ? pinnedRows : undefined}
        slots={{ toolbar: NVCPClearingAllocationTableToolbar }}
        slotProps={{
          toolbar: {
            setRowIsSelected,
            disabled,
            rowIsSelected,
            selectedRowId,
            handleDelete
          }
        }}
        onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => {
          // If whole auth comment is read only, this will prevent user to select a row.
          if (disabled) return;
          // Shim: disableMultipleRowSelection and checkboxSelection doesn't work together at DataGrid v6
          // See: https://github.com/mui/mui-x/issues/5286
          setRowSelectionModel((prevModel) => rowSelectionModel.filter((newId) => !prevModel.includes(newId)));

          // Edit and Delete button state control
          if (rowSelectionModel.length > 0) {
            onActionHandler(true);
            setSelectedRowId(rowSelectionModel[0].toString());
            setRowIsSelected(true);
          } else {
            setTimeout(() => {
              resetToRowUnselectedState();
            });
          }
        }}
        checkboxSelection={true}
        disableRowSelectionOnClick={rowSelectionModel.length === 0 && disabled}
        disableMultipleRowSelection={true}
        columnVisibilityModel={{
          id: false,
          __check__: false
        }}
        rowSelectionModel={rowSelectionModel}
        isRowSelectable={(params: GridRowParams<ClearingAllocation>) => params.row.id !== "Total"}
        hideFooter={true}
        sx={{
          border: 0,
          cursor: "pointer",
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important"
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer": {
            pl: 1
          },
          "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: "none"
          }
        }}
      />
    </ClickAwayListener>
  );
}
