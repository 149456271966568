import { Dispatch, SetStateAction, useState } from "react";
import { BUTTON_LABELS } from "@/constants";
import { Button, FormControl } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Dialog } from "@/components/dialogs";

interface NVCPClearingAllocationTableToolbarProps {
  setEditButtonIsDisabled: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  rowIsSelected: boolean;
  selectedRowId: string;
  modalAction: "Add" | "Delete";
  handleDelete: () => void;
}

export function NVCPClearingAllocationTableToolbar({
  disabled,
  rowIsSelected,
  modalAction,
  handleDelete
}: NVCPClearingAllocationTableToolbarProps) {
  const [modalDialogIsOpen, setModalDialogIsOpen] = useState(false);
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="text"
        color="secondary"
        data-testid="nvcp-table-add-button"
        disabled={disabled}
        onClick={() => {
          setModalDialogIsOpen(true);
        }}
      >
        Add
      </Button>
      <Button
        variant="text"
        color="secondary"
        data-testid="nvcp-table-edit-button"
        disabled={!rowIsSelected || disabled}
        onClick={() => {
          setModalDialogIsOpen(true);
        }}
      >
        Edit
      </Button>
      <Button
        variant="text"
        color="secondary"
        data-testid="nvcp-table-delete-button"
        disabled={!rowIsSelected || disabled}
        onClick={() => handleDelete()}
      >
        Delete
      </Button>

      <Dialog
        data-testid={"clearing-allocation-modal"}
        open={modalDialogIsOpen}
        onClose={() => {
          setModalDialogIsOpen(false);
        }}
        title={modalAction === "Add" ? "Add Clearing Mechanism" : "Update Clearing Mechanism"}
        actions={[
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: () => {
              setModalDialogIsOpen(false);
            }
          },
          {
            label: modalAction === "Add" ? BUTTON_LABELS.ADD : BUTTON_LABELS.SAVE,
            disabled: false,
            onClick: () => {}
          }
        ]}
      >
        <FormControl sx={{ width: "21rem", height: "10rem" }}></FormControl>
      </Dialog>
    </GridToolbarContainer>
  );
}
