import { ArcgisMap, ArcgisZoom } from "@arcgis/map-components-react";
import { Grid } from "@mui/material";
// import defineCustomElements to register custom elements with the custom elements registry
import { defineCustomElements } from "@arcgis/map-components/dist/loader";
import { ArcgisMapCustomEvent } from "@arcgis/map-components/dist/types/components";
import "@arcgis/core/assets/esri/css/main.css";
defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

const { REACT_APP_ARCGIS_WEBMAP_ITEM_ID } = import.meta.env;

interface MapRenderProps {
  mapLoaded: boolean;
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MapRender({ mapLoaded, setMapLoaded }: MapRenderProps) {
  const setMapScale = (event: ArcgisMapCustomEvent<void>) => {
    const view = event.target.view;
    if (view) {
      // Set the minimum and maximum scale
      view.constraints = {
        minScale: 9000000,
        maxScale: 35
      };
    }
  };

  return (
    <Grid
      data-testid="map-render-display"
      visibility={mapLoaded ? "visible" : "hidden"}
      sx={{ flexDirection: "column", flexGrow: 1, height: "100%" }}
      style={{ margin: "0 auto" }}
    >
      <ArcgisMap
        itemId={REACT_APP_ARCGIS_WEBMAP_ITEM_ID}
        onArcgisViewReadyChange={(event) => {
          setMapLoaded(true);
          setMapScale(event);
        }}
      >
        <ArcgisZoom position="bottom-right" layout="vertical"></ArcgisZoom>
      </ArcgisMap>
    </Grid>
  );
}
