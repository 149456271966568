import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AppBar, AppBarProps, Chip, Divider, IconButton, styled, Toolbar, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIos";
import { useResetAtom } from "jotai/utils";
import { useQueryClient } from "@tanstack/react-query";
import { Schema, ValidationError } from "yup";
import { useAtomValue, useSetAtom } from "jotai";
import { useFormContext } from "react-hook-form";

import {
  AR_DISTRIBUTED_ERROR_BANNER,
  AR_FORM,
  AR_MODIFY_ERROR_BANNER,
  AR_SAVE_ERROR_BANNER,
  AR_STATUSES,
  AR_UPLOAD_IN_PROGRESS_ERROR_BANNER,
  AR_SUBMIT_ERROR_BANNER,
  PATH,
  QA_ANSWERS_QUERY_KEY,
  UNSAVED_CHANGES_MODAL,
  UPLOAD_IN_PROGRESS_MODAL,
  WC_ANSWERS_QUERY_KEY,
  BUTTON_LABELS,
  AR_UNEXPECTED_ERROR_BANNER,
  FORM_HELPER_ERROR_CLASS,
  AR_DISTRIBUTED_DISCIPLINE_SME_ERROR_BANNER,
  AR_PERMIT_EXPIRY_DATE_ERROR_BANNER,
  AR_PERMIT_EXPIRY_DATE_ERROR_WITH_NVCP_BANNER,
  REQUESTOR_MODIFY_MODAL,
  DELETE_REQUEST_MODAL,
  PAST_APPROVAL_REQUEST_QUERY_KEY
} from "@/constants";
import {
  ApprovalRequestBase,
  ApprovalRequestStatus,
  AR_ACTION_BUTTONS,
  ARActionButtonComponentName,
  ARActionButtonProps,
  ARFormFields,
  AlertBannerContent,
  UpdateApprovalRequest,
  ApprovalRequestDisciplineStatus,
  APIException
} from "@/interfaces";
import { filterAndMapToQAAnswerItem } from "@/utils";
import { saveARSchema, submitARSchema, noValidationSchema } from "@/validations";
import { useARContext, useAuthorization, usePushNotificationsContext } from "@/context";
import {
  approvalRequestQuestionAnswerListAtom,
  isAllQuestionAnsweredAtom,
  navigationBlockerAtom,
  uploadInProgressAtom,
  snackBarAtom
} from "@/stores";
import { Dialog } from "@/components/dialogs";
import { ARHeaderButtonGroup } from "./ARHeaderButtonGroup";
import { ReturnARForm } from "./ReturnARForm";
import { ReturnPermitForm } from "./ReturnPermitForm";
import { AmendPermitForm } from "./AmendPermitForm";

const ARHeaderAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  height: "4rem",
  zIndex: theme.zIndex.drawer + 2,
  background: "white",
  color: "black",
  top: "4rem",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export function ARHeader() {
  const navigate = useNavigate();
  const { approvalRequestDisciplineId } = useParams();
  const { isGuest, isCoordinator, isSME, isEndorser, userId } = useAuthorization();
  const queryClient = useQueryClient();
  const {
    approvalRequestId,
    approvalRequest,
    approvalRequestType,
    isARDirty,
    saveApprovalRequest,
    deleteApprovalRequest,
    appendAlertBanner,
    resetAlertBanner,
    resetDisciplineTab,
    userIsSMEOfDisciplineOrAdmin,
    userIsSMEOfDiscipline,
    saveDisciplineTabs,
    approvalRequestDisciplines,
    authoriseDisciplineHandler,
    allAuthorisationComments,
    userIsSMEOfAr
  } = useARContext();
  const { isBeingAmended } = usePushNotificationsContext();

  const { clearErrors, getValues, reset, setError } = useFormContext<ARFormFields>();

  const [isModify, setIsModify] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isReturn, setIsReturn] = useState(false);
  const [isPermitReturn, setIsPermitReturn] = useState(false);
  const [isPermitAmend, setIsPermitAmend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const uploadInProgress = useAtomValue(uploadInProgressAtom);
  const allQuestionsAnswered = useAtomValue(isAllQuestionAnsweredAtom);
  const setIsAllQuestionsAnswered = useSetAtom(isAllQuestionAnsweredAtom);
  const setNavigationBlocker = useSetAtom(navigationBlockerAtom);
  const resetNavigationBlockerAtom = useResetAtom(navigationBlockerAtom);
  const setAnswers = useSetAtom(approvalRequestQuestionAnswerListAtom);
  const setSnackBar = useSetAtom(snackBarAtom);

  const isARDetailsUpdatable = useMemo(
    () =>
      ([ApprovalRequestStatus.Draft, ApprovalRequestStatus.New].includes(approvalRequest!.approvalRequestStatus) &&
        !isSME) ||
      (isCoordinator && approvalRequest!.approvalRequestStatus === ApprovalRequestStatus.Submitted),
    [approvalRequest, isCoordinator, isSME]
  );

  const isDistributed = useMemo(
    () => [ApprovalRequestStatus.Distributed].includes(approvalRequest.approvalRequestStatus),
    [approvalRequest]
  );

  const validateAndUpdateAR = useCallback(
    (
      schema: Schema,
      status: ApprovalRequestStatus,
      snackBarMessage: string,
      validationAlertBannerConfig: AlertBannerContent,
      onUpdate?: (response: ApprovalRequestBase) => void,
      onValidationFail?: () => void
    ) => {
      if (uploadInProgress) {
        appendAlertBanner(AR_UPLOAD_IN_PROGRESS_ERROR_BANNER);
        return;
      }

      clearErrors();
      resetAlertBanner();
      setIsError(false);

      const formValues = getValues();
      const request: UpdateApprovalRequest = {
        ...approvalRequest,
        approvalRequestStatus: status,
        title: formValues.title,
        hubId: formValues.hubId,
        siteId: formValues.siteId,
        businessUnitId: formValues.businessUnitId,
        projectId: formValues.projectId,
        costCode: formValues.costCode,
        requiredByDate: formValues.requiredByDate,
        description: formValues.description,
        approvalRequestAnswers: filterAndMapToQAAnswerItem(formValues.approvalRequestAnswers),
        approvalRequestWorkCategories: formValues.approvalRequestWorkCategories,
        extendBy: formValues.extendBy
      };

      schema
        .validate(
          {
            ...request,
            allQuestionsAnswered: allQuestionsAnswered,
            approvalRequestAnswers: formValues.approvalRequestAnswers
          },
          { abortEarly: false }
        )
        .then(() => {
          saveApprovalRequest(
            request,
            (updatedAR: ApprovalRequestBase) => {
              queryClient.invalidateQueries([QA_ANSWERS_QUERY_KEY, approvalRequestId]);
              queryClient.invalidateQueries([WC_ANSWERS_QUERY_KEY, approvalRequestId]);
              // TODO: Not working... this is needed so that when user click save, the comparison card should re-fetch new value
              queryClient.invalidateQueries([
                PAST_APPROVAL_REQUEST_QUERY_KEY,
                approvalRequestId,
                approvalRequest.currentVersion
              ]);
              reset(formValues);
              setSnackBar({
                message: snackBarMessage,
                open: true
              });
              onUpdate?.(updatedAR);
              resetNavigationBlockerAtom();
              setIsLoading(false);
            },
            (error: unknown) => {
              const errorDetails = error as APIException;
              if (errorDetails && errorDetails["UnprocessableEntity"] !== undefined) {
                switch (request.approvalRequestStatus) {
                  case ApprovalRequestStatus.Distributed: {
                    const erroredDisciplines = errorDetails["UnprocessableEntity"]
                      .map((error) => error.propertyName)
                      .join(", ");

                    appendAlertBanner(
                      AR_DISTRIBUTED_DISCIPLINE_SME_ERROR_BANNER(erroredDisciplines, approvalRequest?.hub)
                    );
                    break;
                  }
                  case ApprovalRequestStatus.PermitIssued: {
                    const unprocessableEntityErrors = errorDetails["UnprocessableEntity"];

                    const disciplineListWithoutNVCP = unprocessableEntityErrors
                      .filter((item) => item.propertyName !== "NVCP")
                      .map((item) => item.propertyName);
                    const disciplineToDisplay = disciplineListWithoutNVCP.join(", ");

                    if (disciplineListWithoutNVCP.length !== 0) {
                      appendAlertBanner(AR_PERMIT_EXPIRY_DATE_ERROR_BANNER(disciplineToDisplay));
                    }
                    if (unprocessableEntityErrors.length !== disciplineListWithoutNVCP.length) {
                      appendAlertBanner(AR_PERMIT_EXPIRY_DATE_ERROR_WITH_NVCP_BANNER);
                    }

                    break;
                  }
                }
              } else {
                appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
              }
              setIsLoading(false);
              setIsError(false);
            }
          );
        })
        .catch(({ inner }: ValidationError) => {
          appendAlertBanner(validationAlertBannerConfig);
          inner.forEach(({ path, message }) => {
            setError(path as keyof ARFormFields, { message });
            setIsError(true);
          });
          setIsLoading(false);
          onValidationFail?.();
        });
    },
    [
      uploadInProgress,
      clearErrors,
      resetAlertBanner,
      getValues,
      approvalRequest,
      allQuestionsAnswered,
      appendAlertBanner,
      saveApprovalRequest,
      queryClient,
      approvalRequestId,
      reset,
      setSnackBar,
      resetNavigationBlockerAtom,
      setError
    ]
  );

  const deleteOnClickHandler = useCallback(() => {
    deleteApprovalRequest();
  }, [deleteApprovalRequest]);

  const distributeOnClickHandler = useCallback(() => {
    setIsLoading(true);

    validateAndUpdateAR(
      submitARSchema(isCoordinator),
      ApprovalRequestStatus.Distributed,
      AR_FORM.DISTRIBUTE_AR_SUCCESSFUL,
      AR_DISTRIBUTED_ERROR_BANNER
    );
  }, [isCoordinator, validateAndUpdateAR]);

  const authorisedOnClickHandler = useCallback(() => {
    setIsLoading(true);

    validateAndUpdateAR(
      noValidationSchema,
      ApprovalRequestStatus.PermitIssued,
      AR_FORM.PERMIT_AUTHORISED_SUCCESSFUL,
      AR_DISTRIBUTED_ERROR_BANNER
    );
  }, [validateAndUpdateAR]);

  const saveOnClickHandler = useCallback(
    (onSuccess?: () => void, onFail?: () => void) => {
      setIsLoading(true);
      if (isARDetailsUpdatable) {
        const isARSchemaInUsed = approvalRequest.approvalRequestStatus < ApprovalRequestStatus.Submitted;
        validateAndUpdateAR(
          isARSchemaInUsed ? saveARSchema() : submitARSchema(isCoordinator),
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.New
            ? ApprovalRequestStatus.Draft
            : approvalRequest.approvalRequestStatus,
          AR_FORM.SAVE_AR_SUCCESSFUL,
          AR_SAVE_ERROR_BANNER,
          onSuccess,
          onFail
        );
      }

      if (isDistributed || userIsSMEOfDiscipline) {
        saveDisciplineTabs(() => {
          onSuccess?.();
          resetNavigationBlockerAtom();
        }, onFail);
      }
      setIsLoading(false);
    },
    [
      isARDetailsUpdatable,
      isDistributed,
      userIsSMEOfDiscipline,
      approvalRequest.approvalRequestStatus,
      validateAndUpdateAR,
      isCoordinator,
      saveDisciplineTabs,
      resetNavigationBlockerAtom
    ]
  );

  const submitOnClickHandler = useCallback(() => {
    setIsLoading(true);
    validateAndUpdateAR(
      submitARSchema(isCoordinator),
      ApprovalRequestStatus.Submitted,
      AR_FORM.SUBMIT_AR_SUCCESSFUL,
      AR_SUBMIT_ERROR_BANNER
    );
  }, [isCoordinator, validateAndUpdateAR]);

  const modifyOnClickHandler = useCallback(() => {
    validateAndUpdateAR(
      saveARSchema(),
      ApprovalRequestStatus.Draft,
      AR_FORM.MODIFY_AR_SUCCESSFUL,
      AR_MODIFY_ERROR_BANNER
    );
    setIsModify(false);
  }, [approvalRequest.approvalRequestStatus, validateAndUpdateAR]);

  const { pathname } = useLocation();
  const isAtDetailsTab = pathname.endsWith("details");

  const getCurrentDisciplineAuthComment = useCallback(() => {
    return allAuthorisationComments[approvalRequestDisciplineId ?? ""] ?? [];
  }, [allAuthorisationComments, approvalRequestDisciplineId]);

  function getReturnEnabledStatus(): boolean {
    const enabledCondition = !isLoading && !isARDirty && approvalRequest.returnRequestCount === 0;

    if (approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed) return enabledCondition;

    if (isAtDetailsTab) {
      const assignedDisciplinesAllAuthorised = approvalRequestDisciplines
        .filter((d) => d.primaryApprover === userId || d.secondaryApprover === userId)
        .some((d) => d.status !== ApprovalRequestDisciplineStatus.Authorised);

      return enabledCondition && assignedDisciplinesAllAuthorised;
    } else {
      const currentDisciplineTabIsAuthorised =
        approvalRequestDisciplines.find((d) => d.id === approvalRequestDisciplineId)?.status ===
        ApprovalRequestDisciplineStatus.Authorised;

      return enabledCondition && !currentDisciplineTabIsAuthorised;
    }
  }

  const ACTION_BUTTONS: Record<AR_ACTION_BUTTONS, ARActionButtonProps> = useMemo(
    () => ({
      [AR_ACTION_BUTTONS.Delete]: {
        name: AR_ACTION_BUTTONS.Delete,
        isEnabled: true,
        onClick: () => setIsDelete(true),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab
      },
      [AR_ACTION_BUTTONS.Save]: {
        name: AR_ACTION_BUTTONS.Save,
        isEnabled: isARDirty && !isLoading,
        onClick: () => {
          saveOnClickHandler();
        },
        component: ARActionButtonComponentName.Icon,
        iconName: AR_ACTION_BUTTONS.Save.toLowerCase(),
        isVisible: (isAtDetailsTab && isCoordinator) || isAtDetailsTab || userIsSMEOfDisciplineOrAdmin
      },
      [AR_ACTION_BUTTONS.Submit]: {
        name: AR_ACTION_BUTTONS.Submit,
        isEnabled: !isLoading,
        onClick: () => submitOnClickHandler(),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab
      },
      [AR_ACTION_BUTTONS.Return]: {
        name: AR_ACTION_BUTTONS.Return,
        isEnabled: getReturnEnabledStatus(),
        onClick: () => {
          setIsReturn(true);
        },
        component: isSME ? ARActionButtonComponentName.Icon : ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab || userIsSMEOfDiscipline
      },
      [AR_ACTION_BUTTONS.Modify]: {
        name: AR_ACTION_BUTTONS.Modify,
        isEnabled: true,
        onClick: () => setIsModify(true),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab
      },
      [AR_ACTION_BUTTONS.Distribute]: {
        name: AR_ACTION_BUTTONS.Distribute,
        isEnabled: !isLoading,
        onClick: () => distributeOnClickHandler(),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab
      },
      [AR_ACTION_BUTTONS.SMEAuthorise]: {
        name: BUTTON_LABELS.AUTHORISE,
        isEnabled:
          userIsSMEOfDisciplineOrAdmin &&
          approvalRequest.returnRequestCount === 0 &&
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.Distributed &&
          getCurrentDisciplineAuthComment().some((commentValue) => commentValue.isChecked) &&
          approvalRequestDisciplines.find((d) => d.id === approvalRequestDisciplineId)?.status !==
            ApprovalRequestDisciplineStatus.Authorised,
        onClick: () => {
          authoriseDisciplineHandler();
        },
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: userIsSMEOfAr
      },
      [AR_ACTION_BUTTONS.EndorserAuthorise]: {
        name: BUTTON_LABELS.AUTHORISE,
        isEnabled: !isLoading,
        onClick: () => authorisedOnClickHandler(),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible:
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.DraftPermit &&
          isEndorser &&
          approvalRequest.endorserId === userId // only visible for endorser
      },
      [AR_ACTION_BUTTONS.EndorserReturn]: {
        name: BUTTON_LABELS.RETURN,
        isEnabled: true,
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible:
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.DraftPermit &&
          isEndorser &&
          approvalRequest.endorserId === userId, // only visible for endorser
        onClick: () => {
          setIsPermitReturn(true);
        }
      },
      [AR_ACTION_BUTTONS.Amend]: {
        name: AR_ACTION_BUTTONS.Amend,
        isEnabled: true,
        onClick: () => setIsPermitAmend(true),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: approvalRequest.approvalRequestStatus === ApprovalRequestStatus.PermitIssued
      },
      [AR_ACTION_BUTTONS.CreateACopy]: {
        name: BUTTON_LABELS.CREATEACOPY,
        isEnabled: true,
        onClick: () => null,
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.New
      }
    }),
    [
      isAtDetailsTab,
      isARDirty,
      isCoordinator,
      userIsSMEOfDisciplineOrAdmin,
      isLoading,
      approvalRequest.returnRequestCount,
      approvalRequest.approvalRequestStatus,
      approvalRequest.endorserId,
      isSME,
      isEndorser,
      userId,
      userIsSMEOfDiscipline,
      userIsSMEOfAr,
      approvalRequestDisciplineId,
      approvalRequestDisciplines,
      authorisedOnClickHandler,
      getCurrentDisciplineAuthComment,
      authoriseDisciplineHandler,
      saveOnClickHandler,
      submitOnClickHandler,
      distributeOnClickHandler
    ]
  );

  // @ts-expect-error: Not all statuses require a button group.
  const memoizedGuestButtonGroup: Record<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    return {
      [ApprovalRequestStatus.New]: [ACTION_BUTTONS[AR_ACTION_BUTTONS.Save], ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit]],
      [ApprovalRequestStatus.Draft]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Delete],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateACopy]
      ],
      [ApprovalRequestStatus.Submitted]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Modify],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateACopy]
      ],
      [ApprovalRequestStatus.Distributed]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Modify],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateACopy]
      ],
      [ApprovalRequestStatus.DraftPermit]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Modify],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateACopy]
      ],
      [ApprovalRequestStatus.PermitIssued]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Amend],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateACopy]
      ],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS]);

  // @ts-expect-error: Not all statuses require a button group.
  const memoizedCoordinatorButtonGroup: Record<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    return {
      [ApprovalRequestStatus.New]: [ACTION_BUTTONS[AR_ACTION_BUTTONS.Save], ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit]],
      [ApprovalRequestStatus.Draft]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Delete]
      ],
      [ApprovalRequestStatus.Submitted]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Distribute],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Return]
      ],
      [ApprovalRequestStatus.Distributed]: [],
      [ApprovalRequestStatus.DraftPermit]: [],
      [ApprovalRequestStatus.PermitIssued]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS]);

  // @ts-expect-error: Not all statuses require a button group.
  const memoizedSMEButtonGroup: Record<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    const conditionalSaveButton = userIsSMEOfDiscipline ? [ACTION_BUTTONS[AR_ACTION_BUTTONS.Save]] : [];
    const conditionalReturnButton = isBeingAmended(approvalRequestId) ? [] : [ACTION_BUTTONS[AR_ACTION_BUTTONS.Return]];

    return {
      [ApprovalRequestStatus.New]: [],
      [ApprovalRequestStatus.Draft]: [...conditionalSaveButton],
      [ApprovalRequestStatus.Submitted]: [...conditionalSaveButton],
      [ApprovalRequestStatus.Distributed]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ...conditionalReturnButton,
        ACTION_BUTTONS[AR_ACTION_BUTTONS.SMEAuthorise]
      ],
      [ApprovalRequestStatus.DraftPermit]: [],
      [ApprovalRequestStatus.PermitIssued]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS, approvalRequestId, isBeingAmended, userIsSMEOfDiscipline]);

  // @ts-expect-error: Not all statuses require a button group.
  const memoizedEndoserButtonGroup: Record<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    return {
      [ApprovalRequestStatus.New]: [],
      [ApprovalRequestStatus.Draft]: [],
      [ApprovalRequestStatus.Submitted]: [],
      [ApprovalRequestStatus.Distributed]: [],
      [ApprovalRequestStatus.DraftPermit]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.EndorserAuthorise],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.EndorserReturn]
      ],
      [ApprovalRequestStatus.PermitIssued]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS]);

  const getRoleButtons = (): Record<ApprovalRequestStatus, ARActionButtonProps[]> => {
    if (isEndorser) {
      return memoizedEndoserButtonGroup;
    } else if (isSME) {
      return memoizedSMEButtonGroup;
    } else if (isCoordinator) {
      return memoizedCoordinatorButtonGroup;
    } else if (isGuest) {
      return memoizedGuestButtonGroup;
    }

    // @ts-expect-error: Not all statuses require a button group.
    return {
      [ApprovalRequestStatus.New]: [],
      [ApprovalRequestStatus.Draft]: [],
      [ApprovalRequestStatus.Submitted]: [],
      [ApprovalRequestStatus.Distributed]: [],
      [ApprovalRequestStatus.DraftPermit]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  };

  useEffect(() => {
    if (uploadInProgress) {
      setNavigationBlocker({
        title: UPLOAD_IN_PROGRESS_MODAL.TITLE,
        message: UPLOAD_IN_PROGRESS_MODAL.MESSAGE,
        isBlocked: uploadInProgress
      });
    } else if (isARDirty) {
      setNavigationBlocker({
        title: UNSAVED_CHANGES_MODAL.TITLE,
        message: UNSAVED_CHANGES_MODAL.MESSAGE,
        isBlocked: isARDirty && !isDelete,
        actionButtons: [
          {
            label: UNSAVED_CHANGES_MODAL.DISMISS_BUTTON,
            onClick: (blocker) => {
              setAnswers([]);
              setIsAllQuestionsAnswered(false);
              reset({ ...approvalRequest }, { keepDirty: false });
              resetNavigationBlockerAtom();
              if (approvalRequestDisciplineId) {
                resetDisciplineTab(approvalRequestDisciplineId);
              }
              blocker.proceed?.();
            }
          },
          {
            label: UNSAVED_CHANGES_MODAL.CONFIRM_BUTTON,
            onClick: (blocker) => {
              saveOnClickHandler(blocker.proceed, blocker.reset);
            }
          }
        ]
      });
    } else {
      setNavigationBlocker({
        title: UPLOAD_IN_PROGRESS_MODAL.TITLE,
        message: UPLOAD_IN_PROGRESS_MODAL.MESSAGE,
        isBlocked: false
      });
    }
  }, [
    approvalRequest,
    approvalRequestDisciplineId,
    isARDirty,
    isDelete,
    reset,
    resetDisciplineTab,
    resetNavigationBlockerAtom,
    saveOnClickHandler,
    setAnswers,
    setIsAllQuestionsAnswered,
    setNavigationBlocker,
    uploadInProgress
  ]);

  useEffect(() => {
    if (!isError) return;
    const erroredFields = document.getElementsByClassName(FORM_HELPER_ERROR_CLASS.AR_DETAILS);
    if (erroredFields.length > 0) {
      erroredFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [isError]);

  return (
    <ARHeaderAppBar position="fixed">
      <Fragment>
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => {
                navigate(PATH.MY_REQUESTS);
              }}
              data-testid="arHeaderBackButton"
            >
              <ArrowLeftIcon />
            </IconButton>
            <Typography variant="h5">
              {approvalRequest.referenceNo !== ""
                ? approvalRequest.referenceNo
                : approvalRequest.approvalRequestStatus === ApprovalRequestStatus.New
                  ? AR_FORM.CREATE_MODE_HEADER
                  : AR_FORM.EDIT_MODE_HEADER}{" "}
            </Typography>
            <Typography sx={{ fontSize: "h6.fontSize" }}>
              &nbsp;&nbsp;&nbsp;{approvalRequest.currentVersion.length > 0 && "v"}
              {approvalRequest.currentVersion}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}
          >
            <Typography variant="h6">{approvalRequestType.name}</Typography>
            <Chip
              label={AR_STATUSES[approvalRequest.approvalRequestStatus ?? ApprovalRequestStatus.New]}
              color="primary"
              style={{ marginLeft: "1rem" }}
            />
          </div>
          <div
            style={{
              display: (isAtDetailsTab && (isCoordinator || isGuest)) || isSME || isEndorser ? "inline" : "none"
            }}
          >
            <ARHeaderButtonGroup status={approvalRequest.approvalRequestStatus} buttonGroup={getRoleButtons()} />
          </div>
        </Toolbar>
        <Divider light />
      </Fragment>
      <ReturnARForm
        isReturn={isReturn}
        approvalRequestId={approvalRequest.id}
        dismissModal={() => {
          setIsReturn(false);
        }}
      />
      <ReturnPermitForm
        isReturn={isPermitReturn}
        approvalRequestId={approvalRequest.id}
        approvalRequestDisciplines={approvalRequestDisciplines}
        dismissModal={() => {
          setIsPermitReturn(false);
        }}
      />
      <AmendPermitForm
        approvalRequestId={approvalRequest.id}
        isAmend={isPermitAmend}
        dismissModal={() => {
          setIsPermitAmend(false);
        }}
      />
      {/* TODO: Refactor these two Dialogs into one general purpose one which is configured via state. */}
      <Dialog
        open={isDelete}
        onClose={() => {
          setIsDelete(false);
        }}
        title={DELETE_REQUEST_MODAL.TITLE}
        content={DELETE_REQUEST_MODAL.MESSAGE}
        actions={[
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: () => {
              setIsDelete(false);
            }
          },
          { label: BUTTON_LABELS.DELETE, onClick: deleteOnClickHandler }
        ]}
      />
      <Dialog
        open={isModify}
        onClose={() => {
          setIsModify(false);
        }}
        title={REQUESTOR_MODIFY_MODAL.TITLE}
        content={
          approvalRequest.returnRequestCount > 0 &&
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.Distributed
            ? REQUESTOR_MODIFY_MODAL.RETURN_PENDING_MESSAGE
            : REQUESTOR_MODIFY_MODAL.NO_RETURN_MESSAGE
        }
        actions={[
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: () => {
              setIsModify(false);
            }
          },
          {
            label: BUTTON_LABELS.MODIFY,
            onClick: modifyOnClickHandler
          }
        ]}
      />
    </ARHeaderAppBar>
  );
}
