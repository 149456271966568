import { useQuery } from "@tanstack/react-query";
import {
  AR_DISCIPLINE_QUERY_KEY,
  ASSIGNED_AR_COUNTS_TO_SME_QUERY_KEY,
  AR_DISCIPLINE_CLEARING_ALLOCATION_QUERY_KEY
} from "@/constants";
import { getDisciplinesByApprovalRequestId } from "@/services";
import { apiClient } from "@/api/client";
import { AssignedARCountsByStatus } from "@/interfaces";

const basePath = "/approvalRequestDiscipline";

export function useARDisciplines(id?: string) {
  return useQuery({
    queryKey: [AR_DISCIPLINE_QUERY_KEY, id],
    queryFn: () => getDisciplinesByApprovalRequestId({ approvalRequestId: id! }),
    enabled: id !== undefined,
    initialData: []
  });
}

export function useAssignedARCounts() {
  return useQuery({
    queryKey: [ASSIGNED_AR_COUNTS_TO_SME_QUERY_KEY],
    queryFn: () =>
      apiClient.get<AssignedARCountsByStatus>(`${basePath}/assigned/count`).then((response) => response.data),
    initialData: {
      notUsed: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      notDistributed: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      notStarted: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      wip: {
        approvalRequestCount: 0,
        notificationCount: 0
      },
      authorised: {
        approvalRequestCount: 0,
        notificationCount: 0
      }
    }
  });
}

export function useGetClearingAllocation(approvalRequestDisciplineId?: string, disciplineAuthCommentId?: string) {
  return useQuery({
    queryKey: [AR_DISCIPLINE_CLEARING_ALLOCATION_QUERY_KEY, disciplineAuthCommentId],
    queryFn: () =>
      // apiClient
      //   .get<ClearingAllocation[]>(
      //     `${basePath}/${approvalRequestDisciplineId}/clearing-allocation/${disciplineAuthCommentId}`
      //   )
      //   .then((response) => response.data),
      // TODO: To replace with method above once we have complete with clearing allocation Add story
      Promise.resolve([
        {
          id: "410074a0-ed3a-43fa-a501-a53b5e9faf3d",
          disciplineAuthorisationCommentId: "5b839dd6-c4df-4798-9111-a060a367e59b",
          instrumentId: "9d5afd76-94df-4276-9b53-d1ab0a0e63e6",
          clearingAllocation: 7.8,
          noClearingAfterDate: Date(),
          expiryDate: Date(),
          instrumentRestrictionAreaId: "ce1fe17d-33bc-466c-b05a-f3a3310cf5ba",
          restrictedClearing: "Priority ecological management",
          restrictedClearingAllocation: 1.2,
          referenceNo: "2708/2",
          termsAndConditions: "",
          totalApprovedClearing: 100,
          assignedClearing: 10,
          totalRestrictedClearing: 100,
          assignedRestrictedClearing: 10,
          remainingTotalRestrictedClearing: 90,
          remainingTotalApprovedClearing: 90
        },
        {
          id: "43bb7484-9803-4677-b17d-5f11ba544e1b",
          disciplineAuthorisationCommentId: "5b839dd6-c4df-4798-9111-a060a367e59b",
          instrumentId: "9d5afd76-94df-4276-9b53-d1ab0a0e63e6",
          clearingAllocation: 1.32,
          noClearingAfterDate: Date(),
          expiryDate: Date(),
          instrumentRestrictionAreaId: "ce1fe17d-33bc-466c-b05a-f3a3310cf5ba",
          restrictedClearing: "Priority ecological management",
          restrictedClearingAllocation: 2.3,
          referenceNo: "2708/7",
          termsAndConditions: "",
          totalApprovedClearing: 23.82,
          assignedClearing: 0,
          totalRestrictedClearing: 9.3,
          assignedRestrictedClearing: 0,
          remainingTotalRestrictedClearing: 22.5,
          remainingTotalApprovedClearing: 22.5
        }
      ]),
    enabled: approvalRequestDisciplineId !== undefined && disciplineAuthCommentId !== undefined,
    initialData: []
  });
}
