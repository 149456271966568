import React from "react";
import { Box, Typography } from "@mui/material";
import { useSetAtom } from "jotai";

import {
  ACCEPTED_COMPULSORY_SHAPE_FILE_EXTENSION,
  ACCEPTED_SHAPE_FILE_EXTENSIONS,
  ACCEPTED_ZIPFILE_TYPES,
  ATTACHMENT_MAP_ERRORS
} from "@/constants";
import { AddApprovalRequestMap } from "@/interfaces";
import { uploadInProgressAtom } from "@/stores";
import { useARContext } from "@/context";
import { AttachmentUploadBox } from "../../../features/myRequests/components/AttachmentUploadBox";
import { useAddApprovalRequestMap } from "@/hooks/api/ApprovalRequestHooks";
import JSZip from "jszip";

interface MapUploadProps {
  setIsMapExist: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const MapUpload = ({ setIsMapExist }: MapUploadProps) => {
  const { approvalRequestId } = useARContext();
  const setUploadInProgress = useSetAtom(uploadInProgressAtom);

  setUploadInProgress(false);

  const validateMapUploadFile = async (fileList: FileList, validationFailed: (errorMessage: string) => void) => {
    const file = fileList[0];

    if (!ACCEPTED_ZIPFILE_TYPES.includes(file.type)) {
      validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_REQUIRED_FILE_TYPE);
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_REQUIRED_FILE_TYPE);
      return;
    }

    try {
      const zip = await JSZip.loadAsync(file);
      let hasCompulsoryFile = false;
      let allFilesAllowed = true;
      for (const relativePath in zip.files) {
        const file = zip.files[relativePath];
        // Get the file name
        if (relativePath) {
          const fileName = file.name;

          // Determine the file type based on the file extension
          const fileType = fileName.split(".").pop();
          const fileExtension = "." + fileType;

          if (fileExtension) {
            if (!hasCompulsoryFile) {
              if (ACCEPTED_COMPULSORY_SHAPE_FILE_EXTENSION.includes(fileExtension)) {
                hasCompulsoryFile = true;
              }
            }

            // Check if this entry has an allowed extension
            if (!ACCEPTED_SHAPE_FILE_EXTENSIONS.includes(fileExtension)) {
              allFilesAllowed = false;
              break; // No need to check further if one file is invalid
            }
          }
        }
      }
      if (!hasCompulsoryFile || !allFilesAllowed) {
        validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_REQUIRED_FILE_TYPE);
        return;
      }
    } catch (error) {
      validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_REQUIRED_FILE_TYPE);
      return;
    }
  };

  const { mutate: updateMapMutation } = useAddApprovalRequestMap(approvalRequestId, () => {
    setIsMapExist(true);
    setUploadInProgress(false);
  });

  const uploadFile = async (fileToUpload: File) => {
    setUploadInProgress(true);

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    const addMapCommand: AddApprovalRequestMap = {
      approvalRequestId,
      fileData
    };

    updateMapMutation(addMapCommand);
  };

  const customFooter = (
    <Typography data-testid="upload-footer-display" variant="body2" mt="0.5rem" color="#0000008A">
      Shape Zip File (max. 5M)
    </Typography>
  );

  return (
    <Box data-testid="map-upload-display" flexDirection="column" flexGrow="1" height="100%" m="0 auto">
      <AttachmentUploadBox
        handleUpload={uploadFile}
        customValidation={validateMapUploadFile}
        fillHeight={true}
        customFooter={customFooter}
      />
    </Box>
  );
};
