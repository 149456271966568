import { atom } from "jotai";
import { QAAnswerItem } from "@/interfaces";
import { atomWithReset } from "jotai/utils";

export const approvalRequestTypeAtom = atom<string | null>(null);

export const approvalRequestQuestionAnswerListAtom = atom<QAAnswerItem[]>([]);

export const isAllQuestionAnsweredAtom = atom<boolean>(false);

export const uploadInProgressAtom = atom<boolean>(false);

export const isTrackingCommentUpdatableAtom = atomWithReset<boolean>(false);
export const isAuthorisationCommentUpdatableAtom = atom<boolean>(false);
export const isClearingAllocationUpdatableAtom = atom<boolean>(false);
