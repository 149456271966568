import React from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { AuthError } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { Provider, useAtomValue } from "jotai";
import { QueryClientProvider } from "@tanstack/react-query";

import { MUI_LICENSE_KEY, TITLE } from "@/constants";
import { msalInstance } from "@/authentication";
import { router } from "@/routes";
import { userTheme, adminTheme } from "@/theme";
import { AuthorizationProvider, PushNotificationsProvider, NotificationsProvider } from "@/context";
import { isAdminModeAtom, appDataStore } from "@/stores";
import { client } from "@/services/queryClient";
import { UnauthenticatedComponent } from "@/components";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.addEventCallback((message: EventMessage) => {
  if (message.eventType === EventType.LOGIN_FAILURE) {
    if (message.error instanceof AuthError) {
      //TODO add implementation when the error handling is done
      console.error(message);
    }
  }
});

export function App() {
  const isAdminMode = useAtomValue(isAdminModeAtom);
  document.title = TITLE.AppTitle;

  return (
    <QueryClientProvider client={client}>
      <Provider store={appDataStore}>
        <ThemeProvider theme={isAdminMode ? adminTheme : userTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <React.StrictMode>
              <MsalProvider instance={msalInstance}>
                <AuthenticatedTemplate>
                  <AuthorizationProvider>
                    <NotificationsProvider>
                      <PushNotificationsProvider>
                        <RouterProvider router={router} />
                      </PushNotificationsProvider>
                    </NotificationsProvider>
                  </AuthorizationProvider>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <UnauthenticatedComponent />
                </UnauthenticatedTemplate>
              </MsalProvider>
            </React.StrictMode>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}
